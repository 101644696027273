import React from "react";
import {
    Box,
    FormLabel,
    Button,
    Input,
    FormControl,
    Stack,
    Flex,
    Text,
    GridItem,
    Grid,
  } from '@chakra-ui/react';
  import Upload from '../../../../assets/svg/upload.svg'
  
  import './ViewActive.scss'
  function ViewActiveCard() {
  return (
    <Grid
        h='200px'
        templateRows='repeat(3, 1fr)'
        templateColumns='repeat(5, 1fr)'
        gap={4}
        padding={'0px 50px 36px 50px'}
    >
    <GridItem rowSpan={3} colSpan={1} display={'flex'} gap={'21px'} >
        <p>Avatar</p>
        <Box background={'#EAEDF0'} padding={'24px'} h={'220px'} w={'216px'}>
            <FormControl className="file" height="100%">
                    <Input
                        type="file"
                        height="100%"
                        width="100%"
                        position="absolute"
                        top="0"
                        left="0"
                        opacity="0"
                        aria-hidden="true"
                        accept="image/*"
                    />
                    <Box className="ViewUpload">
                        <img src={Upload} alt="" />
                        <Text textAlign={'center'}>Upload Picture</Text>
                    </Box>
            </FormControl>
        </Box>
    </GridItem>
    <GridItem colSpan={4} rowSpan={3} >
    <Stack direction={['column', 'row']} spacing='24px' mb={'20px'}>
            <Box w='50%'>
                <FormControl className="FormControl">
                    <Flex className="flex">
                        <FormLabel className="FormLabel">Name</FormLabel>
                        <Input className="input" type="text" placeholder="Juan Dela Cruz"/>
                    </Flex>
                </FormControl>
            </Box>
            <Box w='50%'>
                <FormControl className="FormControl">
                    <Flex className="flex">
                        <FormLabel className="FormLabel">Email Address</FormLabel>
                        <Input className="input" type="email" placeholder="jdc@email.com"/>
                    </Flex>
                </FormControl>
            </Box>
        </Stack>
        <Stack direction={['column', 'row']} spacing='24px' mb={'20px'}>
            <Box w='50%'>
                <FormControl className="FormControl">
                    <Flex className="flex">
                        <FormLabel className="FormLabel">Address</FormLabel>
                        <Input className="input" type="text" placeholder="123 One Street BGC, Taguig, Philippines"/>
                    </Flex>
                </FormControl>
            </Box>
            <Box w='50%'>
            </Box>
        </Stack>
        <Stack direction={['column', 'row']} spacing='24px' mb={'20px'}>
            <Box w='50%'>
                <FormControl className="FormControl">
                    <Flex className="flex">
                        <FormLabel className="FormLabel">Contact Number</FormLabel>
                        <Input className="input" type="number" placeholder="+639271234567"/>
                    </Flex>
                </FormControl>
            </Box>
            <Box w='50%'>
            </Box>
        </Stack>
    </GridItem>
    </Grid>
 );
}

export default ViewActiveCard;
