import React from "react";

import {
    Box,
    HStack,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    Flex,
    GridItem,
    Grid,
    SimpleGrid,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Heading,
    Text,
    Progress,
  } from '@chakra-ui/react';
import AddPromoCard from './components/AddPromoCard'
import './Promo.scss'
import { SearchIcon } from "@chakra-ui/icons";
function AddPromo() {
  return (
    <div className="generate">
      <HStack>
        <Box>
          <h1 className='greet'>ADD PROMO</h1> 
          <p className='render__page'>PROMO <span>/ ADD PROMO</span></p>  
        </Box>
      </HStack>
      <Card className="card" mt={'51px'}>
        <CardHeader className="card_header">
          <Heading className="heading" size='md'>ADD PROMO</Heading>
          <Text className="text">Add New Promo Here</Text>
        </CardHeader>
        <AddPromoCard />
      </Card>
    </div>);
}

export default AddPromo;
