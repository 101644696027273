import React, { useState } from "react";
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'

import {
  Table,
  Thead,
  Tbody,
  PopoverBody,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Flex,
  Tr,
  Th,
  Button,
  Td,
  TableCaption,
  TableContainer,
  } from '@chakra-ui/react';
  import './table.scss'
function Tables() {

    const data = [
        { Promo_Name: "Juan Dela Cruz", Promo_Code: "1202321", Promo_Price: "Ph 1,000", Status: "Cash" },
        { Promo_Name: "Juan Dela Cruz", Promo_Code: "1202321", Promo_Price: "Ph 1,000", Status: "Cash" },
        { Promo_Name: "Mark Dela Cruz", Promo_Code: "1202321", Promo_Price: "Ph 1,000", Status: "Cash" },
        { Promo_Name: "Mark Dela Cruz", Promo_Code: "1202321", Promo_Price: "Ph 1,000", Status: "Cash" },
        { Promo_Name: "Mark Dela Cruz", Promo_Code: "1202321", Promo_Price: "Ph 1,000", Status: "Cash" },
    ]
    
  return (
    <>
        <TableContainer className="table__container">
            <Table variant='striped'>
                <TableCaption>Imperial to metric conversion factors</TableCaption>
                <Thead className="thead" bg={'#454545'}>
                    <Tr>
                        <Th>Promo Name</Th>
                        <Th>Promo Code</Th>
                        <Th>Promo Price</Th>
                        <Th>Payment Method</Th>
                        <Th>Action</Th>
                    </Tr>
                </Thead>
                <Tbody className="tbody">
                {data.map((dd, key) => {
                    return (
                        <Tr key={key}>
                            <Td className="active">{dd.Promo_Name}</Td>
                            <Td>{dd.Promo_Code}</Td>
                            <Td>{dd.Promo_Price}</Td>
                            <Td>{dd.Promo_Price}</Td>
                            <Td>
                            <Popover placement='left-start'>
                                    <PopoverTrigger>
                                        <Button bg={'transparent'}><FontAwesomeIcon icon={faEllipsis} /></Button>
                                    </PopoverTrigger>
                                    <PopoverContent w={'100%'}>
                                        <PopoverArrow />
                                            <PopoverBody >
                                                <Flex gap={'3px'}> 
                                                    <Button><EditIcon /></Button>
                                                    <Button><DeleteIcon /></Button>
                                                </Flex>
                                            </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            </Td>
                        </Tr>             
                    )
                })}
                </Tbody>
            </Table>
        </TableContainer>
    </>
  );
}

export default Tables;
