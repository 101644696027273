import React, { useState }  from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    FormLabel,
    Button,
    Input,
    FormControl,
    Stack,
    Flex,
    Text,
    GridItem,
    Grid,
    Img,
    SimpleGrid,
  } from '@chakra-ui/react';
  import Uploads from '../../../../../assets/images/Capture.png'
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons'
  import './style.scss'
  function AddNewCard() {
  const Navigate = useNavigate();
  const [ upload, setUpload ] = useState()
  
  const handleSetImage = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setUpload(imageUrl);
  };
  
  const save = () =>{
    Navigate('/dashboard/merchants');
  }
  
  return (
    <>
    <Grid
        h='200px'
        templateRows='repeat(3, 1fr)'
        templateColumns='repeat(5, 1fr)'
        gap={4}
        padding={'0px 50px 36px 50px'}
    >
    <GridItem rowSpan={3} colSpan={1} display={'flex'} gap={'21px'} >
        <p>Avatar</p>
        <Box background={'#EAEDF0'} padding={'24px'} h={'220px'} w={'216px'} position={'relative'}>
            <Box>
            <Input
                        type="file"
                        height="100%"
                        width="100%"
                        position="absolute"
                        top="0"
                        zIndex={'10'}
                        padding={'0'}
                        left="0"
                        opacity="0"
                        aria-hidden="true"
                        accept="image/*"
                        onChange={handleSetImage}
                    />
                    {upload && <img src={upload} alt="Uploaded Image" /> || <Img src={Uploads} alt="Uploaded Image" m={'0 auto'} mt={'50px'} />  }
            </Box>
        </Box>
    </GridItem>
    <GridItem colSpan={4} rowSpan={3} >
    <Stack direction={['column', 'row']} spacing='24px' mb={'20px'}>
            <Box w='50%'>
                <FormControl className="FormControl">
                    <Flex className="flex">
                        <FormLabel className="FormLabel">Name</FormLabel>
                        <Input className="input" type="text" placeholder="Juan Dela Cruz"/>
                    </Flex>
                </FormControl>
            </Box>
            <Box w='50%'>
                <FormControl className="FormControl">
                    <Flex className="flex">
                        <FormLabel className="FormLabel">Email Address</FormLabel>
                        <Input className="input" type="email" placeholder="jdc@email.com"/>
                    </Flex>
                </FormControl>
            </Box>
        </Stack>
        <Stack direction={['column', 'row']} spacing='24px' mb={'20px'}>
            <Box w='50%'>
                <FormControl className="FormControl">
                    <Flex className="flex">
                        <FormLabel className="FormLabel">Address</FormLabel>
                        <Input className="input" type="text" placeholder="123 One Street BGC, Taguig, Philippines"/>
                    </Flex>
                </FormControl>
            </Box>
            <Box w='50%'>
            </Box>
        </Stack>
        <Stack direction={['column', 'row']} spacing='24px' mb={'20px'}>
            <Box w='50%'>
                <FormControl className="FormControl">
                    <Flex className="flex">
                        <FormLabel className="FormLabel">Contact Number</FormLabel>
                        <Input className="input" type="number" placeholder="+639271234567"/>
                    </Flex>
                </FormControl>
            </Box>
            <Box w='50%'>
            </Box>
        </Stack>
        <Stack className="btn" direction={['column', 'row']} mt={'60px'} spacing='30px'>
            <Button bg='#E93423' onClick={save}>
              <span className="icon"><FontAwesomeIcon icon={faPlus} /></span>ADD NEW MERCHANT
            </Button>
            <Button className="cancel" onClick={save}>
             <span className="icon"><FontAwesomeIcon icon={faXmark} /></span>CANCEL
            </Button>
        </Stack>
    </GridItem>
    </Grid>
    </>
 );
}

export default AddNewCard;
