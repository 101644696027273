import React from "react";
import Login from './pages/login';
import Layout from './pages/layout/index';
import Orders from './pages/orders';
import Dashboard from './pages/dashboard';
import Transaction from './pages/transaction';
import GenerateReport from './pages/GenerateReport';
import PromoList from './pages/PromosList';
import AddPromo from './pages/PromosList/components/AddPromo/AddPromo';
import Merchants from './pages/Merchants/Index';
import AddNewMerchants from './pages/Merchants/components/AddNew/AddNew';
import UploadMerchant from './pages/Merchants/components/Upload/Upload';
import ViewActive from './pages/Merchants/components/ViewActive/ViewActive';
import EditProduct from './pages/Merchants/components/Edit/Edit';
import Riders from './pages/Riders/Index';
import AddRiders from './pages/Riders/components/AddRiders/Add';
import ViewRiders from './pages/Riders/components/ViewRiders/ViewRiders';




import { BrowserRouter, Routes, Route } from 'react-router-dom';

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path='/' element={<Login />} />
        </Route>
      </Routes>
      <Routes>
        <Route path='/dashboard' element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path='orders' element={<Orders />} />
          <Route path='trans' element={<Transaction />} />
          <Route path='generate-report' element={<GenerateReport />} />
          <Route path='promo-list' element={<PromoList />} />
          <Route path='promo-add' element={<AddPromo />} />
          <Route path='merchants' element={<Merchants />} />
          <Route path='Add-New-Merchants' element={<AddNewMerchants />} />
          <Route path='Upload-Merchant' element={<UploadMerchant />} />
          <Route path='View-Active' element={<ViewActive />} />
          <Route path='EditProduct' element={<EditProduct />} />
          <Route path='Riders' element={<Riders />} />
          <Route path='AddRiders' element={<AddRiders />} />
          <Route path='ViewRiders' element={<ViewRiders />} />



        </Route>
      </Routes>
    </BrowserRouter>
  );
  
}

export default AppRouter;
