import React from "react";

import {
    Box,
    HStack,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    Flex,
    GridItem,
    Grid,
    SimpleGrid,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Heading,
    Text,
    Progress,
  } from '@chakra-ui/react';
import UploadCard from './card'
import './Upload.scss'
import { SearchIcon } from "@chakra-ui/icons";
function Upload() {
  return (
    <Box className="Upload" mt={'60px'}>
      <HStack>
        <Box>
          <h1 className='greet'>UPLOAD MERCHANT MASTER FILE</h1> 
          <p className='render__page'>MERCHANT  <span>/ UPLOAD MASTER FILE</span></p>  
        </Box>
      </HStack>
      <Card className="card" mt={'51px'} h={'537px'}>
        <CardHeader className="card_header">
          <Heading className="heading" size='md'>UPLOAD PRODUCT MASTER FILE</Heading>
          <Text className="text">Upload Master File here</Text>
        </CardHeader>
        <UploadCard />
      </Card>
    </Box>
    );
}

export default Upload;
