import React from "react";
import { useNavigate} from 'react-router-dom';

import {
  Box,
  HStack,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Select,
  Card,
  CardHeader,
  Heading,
  Text,
  Container,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons'
import Table from './components/table'
import { SearchIcon } from "@chakra-ui/icons";
import './style.scss';

function Merchants() {
  const navigate = useNavigate();
  const add = () => {
    // 👇️ navigate to /contacts
    navigate('/dashboard/Add-New-Merchants');
  };
  const upload = () => {
    // 👇️ navigate to /contacts
    navigate('/dashboard/Upload-Merchant');
  };
  return(
    <>
      <HStack justifyContent={'space-between'} mt={'60px'}>
        <Box>
          <Text className='greet' fontSize={{base:'15px', '2xl':'30px'}}>MERCHANTS</Text> 
          <Text className='render__page' fontSize={{base:'10px', '2xl':'15px'}}>MERCHANTS <span>/ MERCHANT LIST</span></Text>  
        </Box>
        <Box>
        <InputGroup>
          <Stack direction={['column', 'row']} spacing='30px' alignItems={'center'}>
              <Button onClick={upload} bg='#E93423' fontSize={{ base: '10px', lg: '13px', '2xl': '15px' }} color={'white'} >
              Upload Master File<span className="icon__merchant"><FontAwesomeIcon icon={faPlus} /></span>
              </Button>
              <Button onClick={add} bg='#E93423' fontSize={{ base: '10px', lg: '13px', '2xl': '15px' }} color={'white'} >
              Add New Merchant<span className="icon__merchant"><FontAwesomeIcon icon={faPlus} /></span>
              </Button>
              <Box>
                <Select className="dropdown">
                  <option value="">Active Merchants</option>
                  <option value="">Pending Merchants</option>
                  <option value="">Featured Merchants</option>
                </Select>
              </Box>
              <InputLeftElement
                className="InputRight"
                pointerEvents="none"
                children={<SearchIcon className="SearchIcon" color="gray.300" />}
              />
              <Input 
                variant="outline"
                placeholder={`Search`} 
                width={{ base: '150px', lg: '200px', '2xl': '300px' }} 
              />
          </Stack>
        </InputGroup>
        </Box>
      </HStack>
      <Card className="card_transaction" mt={'51px'}>
        <CardHeader className="card_header">
          <Heading className="heading" size='md'>ACTIVE MERCHANT LIST</Heading>
          <Text className="text">List of Active Merchants</Text>
        </CardHeader>
        <Table />
      </Card>
    </>
    
  );
}

export default Merchants;
