import React from "react";
import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Card,
  CardHeader,
  Heading,
  Text,
  Container,
} from '@chakra-ui/react';
import Table from './components/table'
import { SearchIcon } from "@chakra-ui/icons";
import './style.scss';

function PromoList() {
  return(
    <>
      <HStack justifyContent={'space-between'} mt={'60px'}>
        <Box>
          <h1 className='greet'>PROMOS</h1> 
          <p className='render__page'>PROMOS  <span>/ PROMOS LIST</span></p>  
        </Box>
        <Box>
        <InputGroup>
          <InputLeftElement
            className="InputRight"
            pointerEvents="none"
            children={<SearchIcon className="SearchIcon" color="gray.300" />}
          />
          <Input className="Input" variant="outline" placeholder={`Search`} />
        </InputGroup>
        </Box>
      </HStack>
      <Card className="card__promo" mt={'51px'}>
        <CardHeader className="card_header">
          <Heading className="heading" size='md'>PROMOS LIST</Heading>
          <Text className="text">List of Promo Offers</Text>
        </CardHeader>
        <Table />
      </Card>
    </>
    
  );
}

export default PromoList;
