import React from "react";

import {
    Box,
    HStack,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    Flex,
    GridItem,
    Grid,
    SimpleGrid,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Heading,
    Text,
    Progress,
  } from '@chakra-ui/react';
import AddNewCard from './components/AddNewCard'
import './AddNew.scss'
import { SearchIcon } from "@chakra-ui/icons";
function AddNew() {
  return (
    <Box className="Add" mt={'60px'} position={'relative'} >
      <HStack>
        <Box>
          <h1 className='greet'>ADD NEW MERCHANT</h1> 
          <p className='render__page'>MERCHANT <span>/ ADD NEW MERCHANT</span></p>  
        </Box>
      </HStack>
      <Card className="card" mt={'51px'}>
        <CardHeader className="card_header">
          <Heading className="heading" size='md'>ADD MERCHANT</Heading>
          <Text className="text">Enter Merchant Information Here</Text>
        </CardHeader>
        <AddNewCard />
      </Card>
    </Box>);
}

export default AddNew;
