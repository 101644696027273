import React, { useState } from "react";
import { useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
  Table,
  Thead,
  Tbody,
  PopoverBody,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Flex,
  Link,
  Tr,
  Th,
  Button,
  Td,
  TableCaption,
  TableContainer,
  } from '@chakra-ui/react';
  import './table.scss'
function Tables() {
    const navigate = useNavigate();
    const data = [
        { Merchant_name: "Juan Dela Cruz", Address: "Merchant 1", Contact: "John Santos", Email: "Php 1,000.00", Status: "Active" },
        { Merchant_name: "Juan Dela Cruz", Address: "Merchant 2", Contact: "Carl Santos", Email: "Php 1,000.00", Status: "Active" },
        { Merchant_name: "Mark Dela Cruz", Address: "Merchant 3", Contact: "Chris Santos", Email: "Php 1,000.00", Status: "Active" },
        { Merchant_name: "Mark Dela Cruz", Address: "Merchant 3", Contact: "Chris Santos", Email: "Php 1,000.00", Status: "Active" },
        { Merchant_name: "Mark Dela Cruz", Address: "Merchant 3", Contact: "Chris Santos", Email: "Php 1,000.00", Status: "Active" },
    ]
    const edit = () => {
        // 👇️ navigate to /contacts
        navigate('/dashboard/EditProduct');
      };
      const view = () => {
        // 👇️ navigate to /contacts
        navigate('/dashboard/View-Active');
      };
  return (
    
    <>
    
        <TableContainer className="table__container">
            <Table variant='striped'>
                <TableCaption>Imperial to metric conversion factors</TableCaption>
                <Thead className="thead" bg={'#454545'}>
                    <Tr>
                        <Th>Merchant Name</Th>
                        <Th>Address</Th>
                        <Th>Contact No.</Th>
                        <Th>Email</Th>
                        <Th>Status</Th>
                        <Th>Action</Th>
                    </Tr>
                </Thead>
                <Tbody className="tbody">
                {data.map((dd, key) => {
                    return (
                        <Tr key={key}>
                            <Td className="active">{dd.Merchant_name}</Td>
                            <Td>{dd.Address}</Td>
                            <Td>{dd.Contact}</Td>
                            <Td>{dd.Email}</Td>
                            <Td>{dd.Status}</Td>
                            <Td>
                            <Popover placement='left-start'>
                                    <PopoverTrigger>
                                        <Button bg={'transparent'}><FontAwesomeIcon icon={faEllipsis} /></Button>
                                    </PopoverTrigger>
                                    <PopoverContent w={'100%'}>
                                        <PopoverArrow />
                                            <PopoverBody >
                                                <Flex gap={'3px'}> 
                                                   <Button  onClick={edit}><EditIcon /></Button>
                                                    <Button onClick={view}><DeleteIcon /></Button>
                                                </Flex>
                                            </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            </Td>
                        </Tr>             
                    )
                })}
                </Tbody>
            </Table>
        </TableContainer>
    </>
  );
}

export default Tables;
