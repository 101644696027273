import React from "react";
import {useNavigate} from 'react-router-dom';

import {
    Box,
    HStack,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    Flex,
    GridItem,
    Grid,
    SimpleGrid,
    Card,
    CardHeader,
    Stack,
    CardFooter,
    Heading,
    Text,
    Progress,
    Container,
  } from '@chakra-ui/react';
import ViewActiveCard from './ViewActiveCard'
import ViewTable from './table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons'
import './ViewActive.scss'
import { SearchIcon } from "@chakra-ui/icons";
function ViewActive() {
  const navigate = useNavigate();

    const save = () =>{
        navigate('/dashboard/merchants');
    }
  return (
    <div className="view__active">
      <HStack>
        <Box>
          <h1 className='greet'>VIEW ACTIVE MERCHANT</h1> 
          <p className='render__page'>ACTIVE MERCHANT LIST <span>/  VIEW ACTIVE MERCHANT</span></p>  
        </Box>
      </HStack>
      <Card className="card" mt={'51px'}>
        <CardHeader className="card_header">
          <Heading className="heading" size='md'>VIEW ACTIVE MERCHANT</Heading>
          <Text className="text">View Active Merchant Here</Text>
        </CardHeader>
        <ViewActiveCard />
        <Heading className="heading" size='md' mt={'50px'} ml={'54px'}>PRODUCT LIST</Heading> 
        <ViewTable />
        <Stack className="btn" position={'relative'} direction={['column', 'row']} mt={'50px'} mb={'21px'} spacing='30px'>
            <Button onClick={save} bg='#E93423'>
              <span className="icon"><FontAwesomeIcon icon={faPlus} /></span>ADD NEW MERCHANT
            </Button>
            <Button onClick={() => navigate(-1)} className="cancel">
             <span className="icon"><FontAwesomeIcon icon={faXmark} /></span>CANCEL
            </Button>
        </Stack>
      </Card>
    </div>
    );
}

export default ViewActive;
