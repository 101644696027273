import React, { useRef } from "react";
import { 
     Flex,
     Button, 
     Center, 
     FormControl, 
     Img, 
     Box, 
     Input, 
     useDisclosure, 
     useMergeRefs, 
     InputGroup, 
     InputRightElement, 
     IconButton
} from '@chakra-ui/react';
import klickneatLogo from '../../assets/images/klickneat.png';
import logo from '../../assets/images/login.png';

// import { ReactComponent as EyeSVG } from '../../assets/svg/viewPassword.svg';
import { Link } from 'react-router-dom';
import { HiEye, HiEyeOff } from 'react-icons/hi'
import './style.scss';

function Index({props, ref}) {
 const { isOpen, onToggle } = useDisclosure()
 const inputRef = useRef(null)
 const mergeRef = useMergeRefs(inputRef, ref)
 const onClickReveal = () => {
    onToggle()
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true })
    }
  }
  return (
        <div className="form__container">
            <div className="login_form">
                <div className="form__header">
                    <Box className="logo" bg={'#e53e3e'} ><img src={klickneatLogo} alt="logo" /></Box>
                    <div className="title">Welcome Back</div>
                    <div className="caption">Hello there, sign in to continue</div>
                </div>
                <div className="form__body">
                    <FormControl className="input__login" isRequired>
                        <Input placeholder='Email Address' />
                    </FormControl>
                    <Flex className="input__login">
                    <InputGroup>
                    <InputRightElement className="icoon__password">
                    <IconButton
                        variant="text"
                        aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                        icon={isOpen ? <HiEyeOff /> : <HiEye />}
                        onClick={onClickReveal}
                    />
                    </InputRightElement>
                    <Input
                    id="password"
                    ref={mergeRef}
                    placeholder="Password"
                    name="password"
                    type={isOpen ? 'text' : 'password'}
                    autoComplete="current-password"
                    required
                    {...props}
                    />
                </InputGroup>
                    </Flex>
                    <div className="form__forgot">
                        <p className="forgot"><a href="/#">Forgot Password?</a></p>
                    </div>
                    <Center>
                        <Button className="button"  colorScheme='red'> <Link to='/dashboard' className="button" >Sign In</Link></Button>
                    </Center>
                </div>
            </div>
            <Box
             className="image" 
             display={{ base: 'none', lg: 'block' }} 
            >
                <Img 
                    src={logo} 
                    alt="logo"
                    w={'100%'}
                    h={'100%'}
                />
            </Box>
        </div>
  );
}

export default Index;
