// import core & vendor packages below
// import components below
import { Link } from 'react-router-dom';
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import {
  Box,
  HStack,
  Button,
  Flex,
  GridItem,
  Grid,
  SimpleGrid,
  Card,
  CardHeader,
  Stack,
  StackDivider,
  CardBody,
  Img,
  Heading,
  Text,
  Progress,
  CardFooter,
} from '@chakra-ui/react';
// import assets below
import { ArrowForwardIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons'
import './style.scss';
import logo from '../../assets/images/login.png';
import cycle from '../../assets/svg/cycle.svg';
import store from '../../assets/svg/store.svg'
import clip from '../../assets/svg/clipboard.svg'
import refresh from '../../assets/svg/refresh.svg'
import calendar from '../../assets/svg/calendar.svg'



  
// main component
const Index = () => {
  function refreshPage() {
    window.location.reload(false);
  }
  const state = {
          
    series: [{
      name: 'series1',
      data: [31, 40, 28, 51, 42, 109, 100]
    }, {
      name: 'series2',
      data: [11, 32, 45, 32, 34, 52, 41]
    }],
    options: {
      chart: {
        height: 350,
        type: 'area',
        
      },
      colors: ["#E93423", '#21CE50'],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'month',
        categories: ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"  ]
      },
      tooltip: {
        x: {
          format: 'year'
        },
      },
    },
  };

  return (
    <>
    <Box className='wrapper' maxW={'1820px'} margin={'60px auto 0 auto'} w={{ base:'70%',md: '80%', lg: '100%'}}>
        <HStack justifyContent={'space-between'}>
          <Box>
            <h1 className='greet'>GOOD MORNING ADMIN!</h1> 
            <p className='render__page'>DASHBOARD <span>/ ANALYTICS AND MONITORING</span></p>  
          </Box>
          <Box>
            <Flex alignItems={'center'} gap={'17px'}>
              <p className='render__page'>Last report generated on: June 1, 2021</p>
              DASHBOARD
                <Link to="generate-report"><Button borderRadius={'10px'} h={'60px'} w={{ base:'139px', xl:'239px'}} colorScheme='red'><span><Img mr={'24px'} src={clip} alt="" /></span>GENERATE REPORT</Button></Link>
            </Flex>
          </Box>
      </HStack>
      <Grid
        h='100%'
        mt={'40px'}
        gridTemplateRows={'1fr'}
        gridTemplateColumns={'520px 1fr'}
        
      >
        <GridItem 
          rowSpan={2} 
          colSpan={1} 
          bg={'#1F1F1F'} 
          borderRadius={'15px'} 
          position={'relative'}
          w={{ base:'70%',md: '80%', lg: '100%'}}
        >
          <Img 
            className="upperLeft__img" 
            src={logo}
            maxW={{base: 'auto', lg: '520px'}} 
            w={'100%'} 
            maxH={'358px'} 
            h={'100%'}
          />
          <Box className='image__text'>
            <h4 className='title'>COMPLETED DELIVERIES</h4>
            <h2 className='number'><span>1000</span> <span><Img src={cycle} /></span></h2>
            <h4 className='title'>ONGOING DELIVERIES</h4>
            <h2 className='number'><span>1000</span> <span><Img src={cycle} /></span></h2>
          </Box>
        </GridItem>
        <GridItem colSpan={1} pl={'30px'} position={'relative'} overflow={'hidden'} w={{ base:'70%',md: '80%', lg: '100%'}}>
         <Flex justifyContent={'space-between'}>
            <Text fontSize={'20px'} fontWeight={'medium'} >CURRENT DELIVERIES</Text>
            <Button onClick={refreshPage} borderRadius={'10px'} h={'60px'} w={{ base:'139px', xl:'239px'}} colorScheme='red'><span><Img mr={'24px'} src={refresh} alt="" /></span>REFRESH DELIVERIES</Button>
          </Flex> 
          <SimpleGrid spacing={'35px'} templateColumns={{ base: '200px 200px 200px' ,xl:'370px 370px 370px'}} position={'absolute'} bottom={0}>
            <Card className='card' h={'275px'}>
              <CardBody className='cardBody'>
                <Heading className='color__yellow' size='md'>PENDING DELIVERIES</Heading>
                <Text>
                  <Flex alignItems={'center'}> 
                    <span className='color__yellow text' >150</span><span>- Cancelled Deliveries</span>
                  </Flex>
                </Text>
                <Progress colorScheme='yellow' value={80} />
              </CardBody>
            </Card>
            <Card className='card'>
              <CardBody className='cardBody'>
                <Heading className='color__green' size='md'>COMPLETED DELIVERIES</Heading>
                <Text>
                  <Flex alignItems={'center'}> 
                    <span className='color__green text'>150</span><span>- Cancelled Deliveries</span>
                  </Flex>
                </Text>
                <Progress colorScheme='green' value={80} />
              </CardBody>
            </Card>
            <Card className='card'>
              <CardBody className='cardBody'>
                <Heading className='color__red' size='md'>CANCELLED DELIVERIES</Heading>
                <Text>
                  <Flex alignItems={'center'}> 
                    <span className='color__red text'>150</span><span>- Cancelled Deliveries</span>
                  </Flex>
                </Text>
                <Progress colorScheme='red' value={80} />
              </CardBody>
            </Card>
          </SimpleGrid>
        </GridItem>
      </Grid>
      <Grid
        h='358px'
        mt={'40px'}
        gridTemplateRows={'1fr'}
        gridTemplateColumns={'520px 1fr'}
      >
        <GridItem w={'520px'} mt={'31px'}>
        <Card 
          background={'#FFFFFF 0% 0% no-repeat padding-box'}
          boxShadow={'0px 3px 15px #A8A5E35F'}
          borderRadius={'15px'}
          padding={'25px'}
          w={{ base:'70%',md: '80%', lg: '100%'}}
        >
    <CardHeader>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Box>
          <Heading size='md'>LIST OF MERCHANTS</Heading>
          <Text>Merchant List</Text>
        </Box>
        <Img src={store} fill="black" stroke="yellow" />
      </Flex>
    </CardHeader>
    <CardBody>
      <Stack divider={<StackDivider />} spacing='4' mb={'31px'}>
      <Flex alignItems={'center'} gap={'20px'} >
      <Img w={'82px'} h={'82px'} borderRadius={'50%'} backgroundSize={'cover'} src={logo} />
        <Box w={'100%'}>
          <Flex w={'100%'} justifyContent={'space-between'} alignItems={'center'} >
            <Box>
              <Heading size='xs' textTransform='uppercase' fontSize={'16px'}>
                MERCHANT 1
              </Heading>
              <Text pt='2' fontSize='15px'>
              175 Available Products
              </Text>
            </Box>
            <Box>
            <ArrowForwardIcon fontSize={'30px'} />
          </Box>
          </Flex>
        </Box>
      </Flex>
      </Stack>
      <Stack divider={<StackDivider />} spacing='4' mb={'31px'}>
      <Flex alignItems={'center'} gap={'20px'} >
      <Img w={'82px'} h={'82px'} borderRadius={'50%'} backgroundSize={'cover'} src={logo} />
        <Box w={'100%'}>
          <Flex w={'100%'} justifyContent={'space-between'} alignItems={'center'} >
            <Box>
              <Heading size='xs' textTransform='uppercase' fontSize={'16px'}>
                MERCHANT 1
              </Heading>
              <Text pt='2' fontSize='15px'>
              175 Available Products
              </Text>
            </Box>
            <Box>
            <ArrowForwardIcon fontSize={'30px'} />
          </Box>
          </Flex>
        </Box>
      </Flex>
      </Stack>
      <Stack divider={<StackDivider />} spacing='4' mb={'31px'}>
      <Flex alignItems={'center'} gap={'20px'} >
      <Img w={'82px'} h={'82px'} borderRadius={'50%'} backgroundSize={'cover'} src={logo} />
        <Box w={'100%'}>
          <Flex w={'100%'} justifyContent={'space-between'} alignItems={'center'} >
            <Box>
              <Heading size='xs' textTransform='uppercase' fontSize={'16px'}>
                MERCHANT 1
              </Heading>
              <Text pt='2' fontSize='15px'>
              175 Available Products
              </Text>
            </Box>
            <Box>
            <ArrowForwardIcon fontSize={'30px'} />
          </Box>
          </Flex>
        </Box>
      </Flex>
      </Stack>
      <Stack divider={<StackDivider />} spacing='4' mb={'31px'}>
      <Flex alignItems={'center'} gap={'20px'} >
      <Img w={'82px'} h={'82px'} borderRadius={'50%'} backgroundSize={'cover'} src={logo} />
        <Box w={'100%'}>
          <Flex w={'100%'} justifyContent={'space-between'} alignItems={'center'} >
            <Box>
              <Heading size='xs' textTransform='uppercase' fontSize={'16px'}>
                MERCHANT 1
              </Heading>
              <Text pt='2' fontSize='15px'>
              175 Available Products
              </Text>
            </Box>
            <Box>
            <ArrowForwardIcon fontSize={'30px'} />
          </Box>
          </Flex>
        </Box>
      </Flex>
      </Stack>
      <Stack divider={<StackDivider />} spacing='4' mb={'31px'}>
      <Flex alignItems={'center'} gap={'20px'} >
      <Img w={'82px'} h={'82px'} borderRadius={'50%'} backgroundSize={'cover'} src={logo} />
        <Box w={'100%'}>
          <Flex w={'100%'} justifyContent={'space-between'} alignItems={'center'} >
            <Box>
              <Heading size='xs' textTransform='uppercase' fontSize={'16px'}>
                MERCHANT 1
              </Heading>
              <Text pt='2' fontSize='15px'>
              175 Available Products
              </Text>
            </Box>
            <Box>
            <ArrowForwardIcon fontSize={'30px'} />
          </Box>
          </Flex>
        </Box>
      </Flex>
      </Stack>
      <Stack divider={<StackDivider />} spacing='4' mb={'31px'}>
      <Flex alignItems={'center'} gap={'20px'} >
      <Img w={'82px'} h={'82px'} borderRadius={'50%'} backgroundSize={'cover'} src={logo} />
        <Box w={'100%'}>
          <Flex w={'100%'} justifyContent={'space-between'} alignItems={'center'} >
            <Box>
              <Heading size='xs' textTransform='uppercase' fontSize={'16px'}>
                MERCHANT 1
              </Heading>
              <Text pt='2' fontSize='15px'>
              175 Available Products
              </Text>
            </Box>
            <Box>
            <ArrowForwardIcon fontSize={'30px'} />
          </Box>
          </Flex>
        </Box>
      </Flex>
      </Stack>
      <Stack divider={<StackDivider />} spacing='4' mb={'31px'}>
      <Flex alignItems={'center'} gap={'20px'} >
      <Img w={'82px'} h={'82px'} borderRadius={'50%'} backgroundSize={'cover'} src={logo} />
        <Box w={'100%'}>
          <Flex w={'100%'} justifyContent={'space-between'} alignItems={'center'} >
            <Box>
              <Heading size='xs' textTransform='uppercase' fontSize={'16px'}>
                MERCHANT 1
              </Heading>
              <Text pt='2' fontSize='15px'>
              175 Available Products
              </Text>
            </Box>
            <Box>
            <ArrowForwardIcon fontSize={'30px'} />
          </Box>
          </Flex>
        </Box>
      </Flex>
      </Stack>
    </CardBody>
  </Card>
        </GridItem>
        <GridItem pl={'30px'}  position={'relative'}>
          <SimpleGrid >
            <Card className='card' h={'741px'} w={{ base:'70%',md: '80%', lg: '100%'}} mt={'31px'}>
            <CardHeader className="card_header">
              <Flex justifyContent={'space-between'}>
                <Box>
                  <Heading className="heading" size='md'>DELIVERY PERCENTAGE</Heading>
                  <Text className="text">Percentage of deliveries</Text>
                </Box>
                <Button letterSpacing={'1.13px'} bg={'#F6DAD8'} color={'#E93423'} p={'30px'}>MONTHLY VIEW <span> <Img ml={'60px'} src={calendar}/> </span></Button>
              </Flex>
            </CardHeader>
            <Box h={'100%'}>
              <ReactApexChart options={state.options} series={state.series} type="area" width={'100%'} height={'100%'} />
            </Box>
            <CardFooter>
              <Flex w={'100%'} justifyContent={'center'} gap={'70px'} alignItems={'center'}>
                <Flex color={'#21CE50'} alignItems={'center'}>
                  <Text mr={'60px'} fontSize={'20px'} ><CheckIcon /></Text> 
                  <Text mr={'20px'} fontSize={'50px'} fontWeight={'bold'}>1000</Text>
                  <Text fontSize={'20px'}>Completed Deliveries</Text>
                </Flex>
                <Flex color={'#E93423'} alignItems={'center'}>
                  <Text mr={'60px'} fontSize={'20px'}><CloseIcon /></Text>
                  <Text mr={'20px'} fontSize={'50px'} fontWeight={'bold'}>1000</Text>
                  <Text fontSize={'20px'}>Cancelled Deliveries</Text>
                </Flex>
              </Flex>
            </CardFooter>
            </Card>
          </SimpleGrid>
        </GridItem>
      </Grid>
    </Box>
    </>
  );
};

export default Index;
