import React from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    FormLabel,
    Button,
    Input,
    FormControl,
    Text,
    Stack,
    Select,
  } from '@chakra-ui/react';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons'
  import './Upload.scss'
function UploadCard() {
  const Navigate = useNavigate();
  const save = () =>{
    Navigate('/dashboard/merchants');
  }
  return (
    <>
    <Box className="card__container">
       <Box display={'flex'} >
            <Text>
                Master File
            </Text>
            <Box className="upload">
           
            </Box>
       </Box>
       
       <Stack className="btn" direction={['column', 'row']} mt={'47px'} spacing='30px'>
            <Button bg='#E93423' onClick={save}>
              <span className="icon"><FontAwesomeIcon icon={faPlus} /></span>UPLOAD
            </Button>
            <Button className="cancel" onClick={save}>
             <span className="icon"><FontAwesomeIcon icon={faXmark} /></span>CANCEL
            </Button>
        </Stack>
    </Box>
    </>
    
 );
}

export default UploadCard;
