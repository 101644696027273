import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  HStack,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Select,
  Grid,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Progress,
  Container,
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ViewRidersCard from './ViewRidersCard'
import { SearchIcon } from "@chakra-ui/icons";
import './ViewRiders.scss';

function ViewRiders() {
  const Navigate = useNavigate();
  const save = () =>{
    Navigate('/dashboard/Riders');
  }
  return(
    <>
      <HStack justifyContent={'space-between'} mt={'60px'}>
        <Box>
          <h1 className='greet'>APPROVE / DISAPPROVE RIDER</h1> 
          <p className='render__page'>RIDER LIST <span>/ VIEW RIDER</span></p>  
        </Box>
      </HStack>
      <Card className="card_transaction" mt={'51px'}>
        <CardHeader className="card_header">
          <Heading className="heading" size='md'>RIDER AND MOTORCYCLE INFORMATION</Heading>
          <Text className="text">Enter Rider Information Here</Text>
        </CardHeader>
        <ViewRidersCard />
        <Stack className="btn" position={'relative'} direction={['column', 'row']} mt={'47px'} spacing='30px'>
            <Button bg='#E93423' onClick={save}>
              <span className="icon"><CheckIcon /></span>APPROVE
            </Button>
            <Button className="cancel" onClick={save}>
             <span className="icon"><CloseIcon /></span>DISAPPROVE
            </Button>
        </Stack>
      </Card>
    </>
    
  );
}

export default ViewRiders;
