import { Link } from 'react-router-dom';
import {
  Avatar,
  Box,
  Stack,
  Image,
  Flex,
  HStack,
  IconButton,
  Container,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

import logo from '../../assets/images/login.png';
import klickneat from '../../assets/images/klickneat.png';
import arrow from '../../assets/svg/arrow-right-from-bracket.svg';
import Dashboard from '../../assets/svg/Dashboard.svg';
import cms from '../../assets/svg/cms.svg';
import merchants from '../../assets/svg/merchants.svg';
import motorcycle from '../../assets/svg/motorcycle.svg';
import orders from '../../assets/svg/orders.svg';
import transaction from '../../assets/svg/transaction.svg';


import './style.scss'

export default function Index() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box className='header__container' py={{ base: '10px', '2xl': '20px '}}>
      <Container maxW={'1820px'}>
        <Stack direction={['column', 'row']}
          justifyContent={'space-between'} 
          alignItems={'center'} 
        >
          <Box className='header__left' >
            <Flex gap='30px' alignItems='center'>
              <Image className='img' src={klickneat} bg={'#E93423'} />
              <div className="logo__title">CLICK N EAT ADMIN</div>
            </Flex>
          </Box>
          <Box className='header__right'>
            <Flex gap='18px' alignItems='center'>
              <div className="welcome">WELCOME, ADMIN</div>
              <Avatar src={logo} />
              <Image src={arrow} />
            </Flex>
          </Box>
        </Stack>
        </Container>
      </Box>
      
      <Box className='navbar' h={'86px'}>
        <Flex as={'nav__container'} justifyContent={'space-between'} overflow={'hidden'}>
          <HStack 
            spacing={{ base: '20px', '2xl': '60px '}}
            fontSize={{ base: '10px', '2xl': '13px '}}    
            justifyContent={'center'}
          >
             <IconButton
              position={'absolute'}
              left={'0'}
              ml={'10px'}              
              size={'md'}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ lg: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />
            <Link to="/dashboard">
              <HStack
                as={'nav'}
                spacing={'20px'}
                display={{ base: 'none', lg: 'flex' }}>
                  <Image src={Dashboard} />
                  <Text>DASHBOARD</Text> 
              </HStack>
            </Link>
            <Link to='orders'>
              <HStack
                as={'nav'}
              spacing={'15px'}
                display={{ base: 'none', lg: 'flex' }}>
                  <Image src={orders} />
                  <Text>ORDERS</Text> 
              </HStack>
            </Link>
            <Link to='trans'>
              <HStack
                as={'nav'}
              spacing={'15px'}
                display={{ base: 'none', lg: 'flex' }}>
                  <Image src={transaction} />
                  <Text>TRANSACTIONS</Text>
              </HStack>
            </Link>
            <Link to='/'>
              <HStack
                as={'nav'}
              spacing={'15px'}
                display={{ base: 'none', lg: 'flex' }}>
                  <Image src={cms} />
                <Text>APP CMS</Text> 
              </HStack>
            </Link>
            <Link to='/'>
            <HStack
              as={'nav'}
             spacing={'15px'}
              display={{ base: 'none', lg: 'flex' }}>
                <Image src={cms} />
               <Text>DISCOUNTS</Text>
            </HStack>
            </Link>
            <Link to='promo-list'>
            <HStack
              as={'nav'}
             spacing={'15px'}
              display={{ base: 'none', lg: 'flex' }}>
                <Image src={Dashboard} />
               <Text>PROMOS</Text>
            </HStack>
            </Link>
            <Link to='merchants'>
            <HStack
              as={'nav'}
             spacing={'15px'}
              display={{ base: 'none', lg: 'flex' }}>
                <Image src={merchants} />
              <Text>MERCHANTS</Text>
            </HStack>
            </Link>
            <Link to='Riders'>
            <HStack
              as={'nav'}
             spacing={'15px'}
              display={{ base: 'none', lg: 'flex' }}>
                <Image src={motorcycle} />
             <Text>RIDERS</Text>  
            </HStack>
            </Link>
            <Link to='/'>
            <HStack
              as={'nav'}
             spacing={'15px'}
              display={{ base: 'none', lg: 'flex' }}>
                <Image src={Dashboard} />
              <Text>ACCOUNTS</Text> 
            </HStack>
            </Link>
          </HStack>
        </Flex>
        {isOpen ? (
          <Box pb={4} display={{ lg: 'none' }}>
            <Stack as={'nav'} spacing={4}>
                <Link to='/1'>DASHBOARD</Link>
            </Stack>
          </Box>
        ) : null}
      </Box>
  </>
  )
}