import React from "react";
import {
    Box,
    FormLabel,
    Button,
    Input,
    FormControl,
    Stack,
    Flex,
  } from '@chakra-ui/react';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons'
  import './Card.scss';
function GenerateReportCard() {
  return (
    <Box className="card__container">
        <Flex gap={'40px'}>
            <Box w={'522px'}>
                <FormControl>
                    <Flex alignItems={'center'}>
                        <FormLabel className="FormLabel">Start Date</FormLabel>
                        <Input w={'370px'} type='date' />
                    </Flex>
                </FormControl>
            </Box>
            <Box w={'522px'}>
                <FormControl>
                    <Flex alignItems={'center'}>
                        <FormLabel className="FormLabel">End Date</FormLabel>
                        <Input w={'370px'} type='date' />
                    </Flex>
                </FormControl>
            </Box>
        </Flex>
        <Stack  direction={['column', 'row']} mt={'60px'} spacing='30px'>
            <Button className="generate__btn" bg='#E93423'>
              <span className="icon"><FontAwesomeIcon icon={faPlus} /></span>GENERATE REPORT
            </Button>
            <Button className="cancel__generate">
             <span className="icon"><FontAwesomeIcon icon={faXmark} /></span>CANCEL
            </Button>
        </Stack>
    </Box>
 );
}

export default GenerateReportCard;
