import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'

import {
  Table,
  Thead,
  Tbody,
  chakra,
  Tr,
  Th,
  Button,
  Td,
  TableCaption,
  TableContainer,
  } from '@chakra-ui/react';
  import './table.scss'
function Tables() {

    const data = [
        { Merchant_name: "Juan Dela Cruz", Address: "Merchant 1", Contact: "John Santos", Email: "Php 1,000.00", Status: "Active" },
        { Merchant_name: "Juan Dela Cruz", Address: "Merchant 2", Contact: "Carl Santos", Email: "Php 1,000.00", Status: "Active" },
        { Merchant_name: "Mark Dela Cruz", Address: "Merchant 3", Contact: "Chris Santos", Email: "Php 1,000.00", Status: "Active" },
        { Merchant_name: "Mark Dela Cruz", Address: "Merchant 3", Contact: "Chris Santos", Email: "Php 1,000.00", Status: "Active" },
        { Merchant_name: "Mark Dela Cruz", Address: "Merchant 3", Contact: "Chris Santos", Email: "Php 1,000.00", Status: "Active" },
    ]
    
  return (
    <>
        <TableContainer className="table__container">
            <Table variant='striped'>
                <TableCaption>Imperial to metric conversion factors</TableCaption>
                <Thead className="thead" bg={'#454545'}>
                    <Tr>
                        <Th>Merchant Name</Th>
                        <Th>Address</Th>
                        <Th>Contact No.</Th>
                        <Th>Email</Th>
                        <Th>Status</Th>
                        <Th>Action</Th>
                    </Tr>
                </Thead>
                <Tbody className="tbody">
                {data.map((dd, key) => {
                    return (
                        <Tr key={key}>
                            <Td className="active">{dd.Merchant_name}</Td>
                            <Td>{dd.Address}</Td>
                            <Td>{dd.Contact}</Td>
                            <Td>{dd.Email}</Td>
                            <Td>{dd.Status}</Td>
                            <Td><FontAwesomeIcon icon={faEllipsis} /></Td>
                        </Tr>             
                    )
                })}
                </Tbody>
            </Table>
        </TableContainer>
    </>
  );
}

export default Tables;
