import React from "react";
import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Card,
  CardHeader,
  Container,
  Heading,
  Text,
} from '@chakra-ui/react';
import Table from './components/table'
import { SearchIcon } from "@chakra-ui/icons";

function Index() {
  return(
    <>
      <HStack justifyContent={'space-between'} mt={'60px'}>
        <Box>
          <Text className='greet' fontSize={{base:'15px', '2xl':'30px'}}>Orders</Text> 
          <Text className='render__page' fontSize={{base:'10px', '2xl':'15px'}}>Orders <span>/ Orders List</span></Text>  
        </Box>
        <Box>
        <InputGroup>
          <InputLeftElement
            className="InputRight"
            pointerEvents="none"
            children={<SearchIcon className="SearchIcon" color="gray.300" />}
          />
          <Input width={{ base: '150px', lg: '200px', '2xl': '300px' }}  className="Input" variant="outline" placeholder={`Search`} />
        </InputGroup>
        </Box>
      </HStack>
      <Card className="card_transaction" mt={'51px'}>
        <CardHeader className="card_header">
          <Heading className="heading" size='md'>Orders List</Heading>
          <Text className="text">List of Orders</Text>
        </CardHeader>
        <Table />
      </Card>
    </>
    
  );
}

export default Index;
