import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  HStack,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Select,
  Card,
  CardHeader,
  Heading,
  Text,
  Container,
  Flex,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons'
import Table from './components/table'
import { SearchIcon } from "@chakra-ui/icons";
import './Riders.scss';

function Riders() {
  const navigate = useNavigate();
  
  const AddRider = () =>{
    navigate('/dashboard/AddRiders');
  }
  
  return(
    <>
      <HStack justifyContent={'space-between'} mt={'60px'}>
        <Box>
          <h1 className='greet'>RIDERS</h1> 
          <p className='render__page'>RIDERS  <span>/ RIDERS LIST</span></p>  
        </Box>
        <Box>
        <InputGroup>
          <Stack position={'relative'} direction={['column', 'row']} spacing='30px' alignItems={'center'}>
            <Box>
              <Select className="dropdown">
                <option value="">Status</option>
                <option value="">Pending Merchants</option>
                <option value="">Featured Merchants</option>
              </Select>
            </Box>
            <InputLeftElement
              className="InputRight"
              pointerEvents="none"
              children={<SearchIcon className="SearchIcon" color="gray.300" />}
            />
            <Input 
              width={{ base: '150px', lg: '200px', '2xl': '300px' }}
              variant="outline" 
              placeholder={`Search`} />
          </Stack>
        </InputGroup>
        </Box>
      </HStack>
      <Card className="card_transaction" mt={'51px'}>
        <CardHeader className="card_header">
          <Flex justifyContent={'space-between'}>
            <Box>
              <Heading className="heading" size='md'>RIDERS LIST</Heading>
              <Text className="text">List of Riders</Text>
            </Box>
            <Button onClick={AddRider} bg={'#E93423'} color={'white'}>Add Rider</Button>
          </Flex>
        </CardHeader>
        <Table />
      </Card>
    </>
    
  );
}

export default Riders;
