import { Outlet } from 'react-router-dom';
import TopBar from '../header/Index';
import {
  Box,
  Container,
} from '@chakra-ui/react';
// import assets below
import './style.scss';

// main component
const index = () => {
  return (
    <>
      <TopBar />
    <Container maxW={'1820px'}>
        <Box className='dashboard__container'>
            <Outlet />
        </Box>
    </Container>
    </>
  )
  
};

export default index;
