import React from "react";
import {useNavigate} from 'react-router-dom';

import {
    Box,
    FormLabel,
    Button,
    Input,
    FormControl,
    Stack,
    Flex,
    Select,
  } from '@chakra-ui/react';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
  import './Edit.scss';
function EditCard() {
    const navigate = useNavigate();

    const save = () =>{
        navigate('/dashboard/merchants');
    }
  return (
    <Box className="card__container">
        <Stack direction={['column', 'row']} spacing='24px' mb={'20px'}>
            <Box w='50%'>
                <FormControl className="FormControl">
                    <Flex className="flex">
                        <FormLabel className="FormLabel">Promo Name</FormLabel>
                        <Input className="input" type="text" placeholder="Promo 1"/>
                    </Flex>
                </FormControl>
            </Box>
            <Box w='50%'>
            <FormControl className="FormControl">
                    <Flex className="flex">
                        <FormLabel className="FormLabel" >Payment Method</FormLabel>
                        <Select className="select">
                            <option>Gcash</option>
                            <option>Paymaya</option>
                        </Select>
                    </Flex>
                </FormControl>
            </Box>
        </Stack>
        <Stack direction={['column', 'row']} spacing='24px' mb={'20px'}>
            <Box w='50%'>
                <FormControl className="FormControl">
                    <Flex className="flex">
                        <FormLabel className="FormLabel">Promo Code</FormLabel>
                        <Input className="input" type="text" placeholder="CNE100"/>
                    </Flex>
                </FormControl>
            </Box>
            <Box w='50%'>
                
            </Box>
        </Stack>
        <Stack direction={['column', 'row']} spacing='24px'>
            <Box w='50%'>
                <FormControl className="FormControl">
                    <Flex className="flex">
                        <FormLabel className="FormLabel">Promo Price</FormLabel>
                        <Input className="input" type="text" placeholder="P 100.00"/>
                    </Flex>
                </FormControl>
            </Box>
            <Box w='50%'></Box>
        </Stack>
        <Stack className="btn" position={'relative'} direction={['column', 'row']} mt={'60px'} spacing='30px'>
            <Button onClick={save} bg='#E93423'>
              <span className="icon"><FontAwesomeIcon icon={faCheck} /></span>SAVE
            </Button>
            <Button onClick={() => navigate(-1)} className="cancel">
             <span className="icon"><FontAwesomeIcon icon={faXmark} /></span>CANCEL
            </Button>
        </Stack>
    </Box>
 );
}

export default EditCard;
