
import React, { useState } from "react";
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'

import {
  Table,
  Thead,
  Tbody,
  PopoverBody,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Tr,
  Th,
  Button,
  Td,
  TableCaption,
  TableContainer,
  Flex,
  } from '@chakra-ui/react';
  import './table.scss'
function Tables() {

    const data = [
        { name: "Juan Dela Cruz", Merchant_Name: "Merchant 1", Rider_Name: "John Santos", Order_Total: "Php 1,000.00", Status: "Completed" },
        { name: "Juan Dela Cruz", Merchant_Name: "Merchant 2", Rider_Name: "Carl Santos", Order_Total: "Php 1,000.00", Status: "Completed" },
        { name: "Mark Dela Cruz", Merchant_Name: "Merchant 3", Rider_Name: "Chris Santos", Order_Total: "Php 1,000.00", Status: "Completed" },
        { name: "Mark Dela Cruz", Merchant_Name: "Merchant 3", Rider_Name: "Chris Santos", Order_Total: "Php 1,000.00", Status: "Completed" },
        { name: "Mark Dela Cruz", Merchant_Name: "Merchant 3", Rider_Name: "Chris Santos", Order_Total: "Php 1,000.00", Status: "Completed" },
    ]
    
  return (
    <>
        <TableContainer className="table__container">
            <Table variant='striped'>
                <TableCaption>Imperial to metric conversion factors</TableCaption>
                <Thead className="thead" bg={'#454545'}>
                    <Tr>
                        <Th>Customer Name</Th>
                        <Th>Merchant Name</Th>
                        <Th>Rider Name</Th>
                        <Th>Order Total</Th>
                        <Th>Status</Th>
                        <Th>Action</Th>
                    </Tr>
                </Thead>
                <Tbody className="tbody">
                {data.map((dd, key) => {
                    return (
                        <Tr key={key}>
                            <Td className="active">{dd.name}</Td>
                            <Td>{dd.Merchant_Name}</Td>
                            <Td>{dd.Rider_Name}</Td>
                            <Td>{dd.Order_Total}</Td>
                            <Td>{dd.Status}</Td>
                            <Td>
                                <Popover placement='left-start'>
                                    <PopoverTrigger>
                                        <Button bg={'transparent'}><FontAwesomeIcon icon={faEllipsis} /></Button>
                                    </PopoverTrigger>
                                    <PopoverContent w={'100%'}>
                                        <PopoverArrow />
                                            <PopoverBody >
                                                <Flex gap={'3px'}> 
                                                    <Button><EditIcon /></Button>
                                                    <Button><DeleteIcon /></Button>
                                                </Flex>
                                            </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            </Td>
                        </Tr>             
                    )
                })}
                </Tbody>
            </Table>
        </TableContainer>
    </>
  );
}

export default Tables;