import React, { useState }  from "react";
import imgs from '../../../../assets/images/Capture.png';

import { useNavigate } from "react-router-dom";import {
  Input,
  FormLabel,
  SimpleGrid,
  Img,
  Text,
  Box,
} from '@chakra-ui/react';
import './AddRiders.scss'
function AddRidersCard() {
    const Navigate = useNavigate();
    const [ upload, setUpload ] = useState()
  
    const handleSetImage = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setUpload(imageUrl);
  };
  const save = () =>{
    Navigate('/dashboard/merchants');
  }
  return (
    <>
    <SimpleGrid minChildWidth='331px' spacing='30px' spacingY={'20px'}>
        <Box height='auto'>
            <Box display={'flex'} gap={'21px'} ml={'50px'} position={'relative'} >
                <Text>
                    Rider Photo
                </Text>
                <Box background={'#EAEDF0'} padding={'24px'} h={'220px'} w={'216px'} position={'relative'}>
                    <Input
                        type="file"
                        height="100%"
                        width="100%"
                        position="absolute"
                        top="0"
                        zIndex={'10'}
                        padding={'0'}
                        left="0"
                        opacity="0"
                        aria-hidden="true"
                        accept="image/*"
                        onChange={handleSetImage}
                    />
                    {upload && <Img src={upload} alt="Uploaded Image" /> || <Img src={imgs} alt="Uploaded Image" m={'0 auto'} mt={'50px'} />  }
                </Box>
            </Box>
        </Box>
        <Box height='auto'>
        <Box display={'flex'} gap={'21px'} ml={'50px'} position={'relative'} >
                <Text>
                    Driver's License
                </Text>
                <Box background={'#EAEDF0'} padding={'24px'} h={'220px'} w={'216px'} position={'relative'}>
                    <Input
                        type="file"
                        height="100%"
                        width="100%"
                        position="absolute"
                        top="0"
                        zIndex={'10'}
                        padding={'0'}
                        left="0"
                        opacity="0"
                        aria-hidden="true"
                        accept="image/*"
                        onChange={handleSetImage}
                    />
                    {upload && <Img src={upload} alt="Uploaded Image" /> || <Img src={imgs} alt="Uploaded Image" m={'0 auto'} mt={'50px'} />  }
                </Box>
            </Box>
        </Box>
        <Box height='auto'>
        <Box display={'flex'} gap={'21px'} ml={'50px'} position={'relative'} >
                <Text>
                    Motorcycle Photo
                </Text>
                <Box background={'#EAEDF0'} padding={'24px'} h={'220px'} w={'216px'} position={'relative'}>
                    <Input
                        type="file"
                        height="100%"
                        width="100%"
                        position="absolute"
                        top="0"
                        zIndex={'10'}
                        padding={'0'}
                        left="0"
                        opacity="0"
                        aria-hidden="true"
                        accept="image/*"
                        onChange={handleSetImage}
                    />
                    {upload && <Img src={upload} alt="Uploaded Image" /> || <Img src={imgs} alt="Uploaded Image" m={'0 auto'} mt={'50px'} />  }
                </Box>
            </Box>
        </Box>
        <Box height='auto'>
        <Box display={'flex'} gap={'21px'} ml={'50px'} position={'relative'} >
                <Text>
                    OR / CR
                </Text>
                <Box background={'#EAEDF0'} padding={'24px'} h={'220px'} w={'216px'} position={'relative'}>
                    <Input
                        type="file"
                        height="100%"
                        width="100%"
                        position="absolute"
                        top="0"
                        zIndex={'10'}
                        padding={'0'}
                        left="0"
                        opacity="0"
                        aria-hidden="true"
                        accept="image/*"
                        onChange={handleSetImage}
                    />
                    {upload && <Img src={upload} alt="Uploaded Image" /> || <Img src={imgs} alt="Uploaded Image" m={'0 auto'} mt={'50px'} />  }
                </Box>
            </Box>
        </Box>
    </SimpleGrid>
    <SimpleGrid minChildWidth='522px' spacing='30px' spacingY={'20px'} mt={'60px'}>
        <Box height='auto'>
            <Box display={'flex'} alignItems={'center'} ml={'50px'} w={{base: 'auto', md:'500px'}} placeContent={'space-between'} position={'relative'} >
                <FormLabel>First Name</FormLabel>
                <Input w={'370px'} type='text' bg={'#EAEDF0'} />
            </Box>
        </Box>
        <Box height='auto'>
            <Box display={'flex'} alignItems={'center'} ml={'50px'} w={{base: 'auto',md:'500px'}} placeContent={'space-between'} position={'relative'} >
                <FormLabel>Last Name</FormLabel>
                <Input w={'370px'} type='text' bg={'#EAEDF0'} />
            </Box>
        </Box>
        <Box height='auto'>
            <Box display={'flex'} alignItems={'center'} ml={'50px'} w={{base: 'auto',md:'500px'}} placeContent={'space-between'} position={'relative'} >
                <FormLabel>Email Address</FormLabel>
                <Input w={'370px'} type='text' bg={'#EAEDF0'} />
            </Box>
        </Box>
        <Box height='auto'>
            <Box display={'flex'} alignItems={'center'} ml={'50px'} w={{base: 'auto', md:'500px'}} placeContent={'space-between'} position={'relative'} >
                <FormLabel>Contact No.</FormLabel>
                <Input w={'370px'} type='phone' bg={'#EAEDF0'} />
            </Box>
        </Box>
        <Box height='auto'>
            <Box display={'flex'} alignItems={'center'} ml={'50px'} w={{base: 'auto',md:'500px'}} placeContent={'space-between'} position={'relative'} >
                <FormLabel>Birthdate</FormLabel>
                <Input w={'370px'} type='date' bg={'#EAEDF0'} />
            </Box>
        </Box>
        <Box height='auto'>
            <Box display={'flex'} alignItems={'center'} ml={'50px'} w={{base: 'auto',md:'500px'}} placeContent={'space-between'} position={'relative'} >
                <FormLabel>Driver's License No.</FormLabel>
                <Input w={'370px'} type='text' bg={'#EAEDF0'} />
            </Box>
        </Box>
        <Box height='auto'>
            <Box display={'flex'} alignItems={'center'} ml={'50px'} w={{base: 'auto', md:'500px'}} placeContent={'space-between'} position={'relative'} >
                <FormLabel>Vehicle Brand</FormLabel>
                <Input w={'370px'} type='text' bg={'#EAEDF0'} />
            </Box>
        </Box>
        <Box height='auto'>
            <Box display={'flex'} alignItems={'center'} ml={'50px'} w={{base: 'auto',md:'500px'}} placeContent={'space-between'} position={'relative'} >
                <FormLabel>Vehicle Model</FormLabel>
                <Input w={'370px'} type='text' bg={'#EAEDF0'} />
            </Box>
        </Box>
        <Box height='auto'>
            <Box display={'flex'} alignItems={'center'} ml={'50px'} w={{base: 'auto',md:'500px'}} placeContent={'space-between'} position={'relative'} >
                <FormLabel>Vehicle Color</FormLabel>
                <Input w={'370px'} type='text' bg={'#EAEDF0'} />
            </Box>
        </Box>
        <Box height='auto'>
            <Box display={'flex'} alignItems={'center'} ml={'50px'} w={{base: 'auto', md:'500px'}} placeContent={'space-between'} position={'relative'} >
                <FormLabel>Vehicle Plate No.</FormLabel>
                <Input w={'370px'} type='text' bg={'#EAEDF0'} />
            </Box>
        </Box>
        <Box height='auto'>
            <Box display={'flex'} alignItems={'center'} ml={'50px'} w={{base: 'auto',md:'500px'}} placeContent={'space-between'} position={'relative'} >
                <FormLabel>OR No.</FormLabel>
                <Input w={'370px'} type='text' bg={'#EAEDF0'} />
            </Box>
        </Box>
        <Box height='auto'>
            <Box display={'flex'} alignItems={'center'} ml={'50px'} w={{base: 'auto',md:'500px'}} placeContent={'space-between'} position={'relative'} >
                <FormLabel>CR No.</FormLabel>
                <Input w={'370px'} type='text' bg={'#EAEDF0'} />
            </Box>
        </Box>
    </SimpleGrid>
        </>
    );
}

export default AddRidersCard;
