import React from "react";

import {
    Box,
    HStack,
    Container,
    Card,
    CardHeader,
    Heading,
    Text,
  } from '@chakra-ui/react';
import GenerateReportCard from './components/Card'
import './style.scss'
import { SearchIcon } from "@chakra-ui/icons";
function GenerateReport() {
  return (
    <Box className="generate" mt={'60px'}>
      <HStack>
        <Box>
          <h1 className='greet'>GENERATE REPORT</h1> 
          <p className='render__page'>DASHBOARD <span>/ GENERATE REPORT</span></p>  
        </Box>
      </HStack>
      <Card className="card" mt={'51px'}>
        <CardHeader className="card_header">
          <Heading className="heading" size='md'>GENERATE REPORT</Heading>
          <Text className="text">Generate Report Here</Text>
        </CardHeader>
        <GenerateReportCard />
      </Card>
    </Box>
    );
}

export default GenerateReport;
